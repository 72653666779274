import React from 'react'
import Link from 'gatsby-link'
import Layout from '../../components/Layout'
import Table from 'react-bootstrap/Table'

export default class Index extends React.Component {
    render() {
        return (
            <Layout>
                <section className="section">
                    <div className="container">
                        <div className="content">
                            <h1>Elbil
                            </h1>
                            <ul>
                                <li>
                                    Ved lading av elbil skal det benyttes egen kurs som er beskyttet med
                                    jordfeilbryter type B.
                                    Sjekkliste mot elektriske branner:
                                </li>
                            </ul>
                            <h1>Sjekkliste mot elektriske branner:</h1>
                            <ul>
                                <li>
                                    Er noen av kursene i huset overbelastet? Kjenn på sikringer, koblingsbokser og
                                    kontakter om de er unormalt varme. Husk spesielt inntakssikringene (på loftet eller
                                    kjeller)! SKRU DE GODT TIL!
                                </li>
                                <li>
                                    Kast gamle elektriske apparater.
                                </li>
                                <li>
                                    Utstyr som har nådd eller passert «pensjonsalderen» kan ha slitasjeskader som
                                    gjør
                                    det brannfarlig.
                                </li>
                                <li>
                                    Fjern utsatte løse ledninger og erstatt dem med faste installasjoner. Det er
                                    forbudt
                                    med skjøteledninger gjennom døråpninger fra rom til rom. Skjøteledning skal ikke
                                    benyttes til varmeovn.
                                </li>
                                <li>
                                    Erstatt løse lamper og frittstående ovner med faste installasjoner i
                                    barnerom.
                                </li>
                                <li>
                                    Skift ut støpsler, kontakter og brytere som har sprekker eller andre
                                    skader.
                                </li>
                                <li>
                                    Les nøye bruksanvisninger på det elektriske utstyret ditt.
                                    Fritt etter: «Uvettig bruk av elektrisk utstyr» (Norsk Brannvern Forening og
                                    Energi Norge)
                                </li>
                                <li>
                                    Ved lading av elektrisk utstyr, bruk lader tiltenkt utstyret.
                                </li>
                            </ul>
                            <h3>Vi tar tilsyn av ditt elektriske anlegge gratis, ta kontakt med Frank på 32745000</h3>
                        </div>
                    </div>
                </section>
            </Layout>
        )
    }
}
